<template>
  <div>
    <b-container
      class="base-container-x pt-2"
      style="min-height: 400px; background-color: #fff"
    >
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister
            :currentStep="3"
            :titleListSteps="this.titleListSteps"
          />
        </b-col>
        <b-col
          xl="9"
          lg="9"
          sm="12"
          md="12"
          class="border rounded py-4 py-md-5 border-light-gray"
        >
          <h3 class="content-header-title text-primary-dark">
            {{ $t("Document") }}
          </h3>
          <div class="mt-4">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                <b-form-group>
                  <label
                    for="apply_for"
                    class="text-primary-dark font-weight-600"
                    >{{ $t("Logo") }}<span class="text-danger">*</span></label
                  >
                  <validation-provider
                    :name="$t('Logo')"
                    :rules="!tempSchool.doc_logo ? 'required' : ''"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="form.doc_logo"
                      :placeholder="$t('No file chosen')"
                      :drop-placeholder="$t('Drop file here')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small>{{ tempSchool.doc_logo }}</small>
                </b-form-group>
                <b-form-group
                  :description="
                    $t(
                      '* Curriculum Vitae (CV) for the Director or the primary instructor, if the director is not involved in the yoga teacher training course and any other supporting yoga instructor.Photocopies of relevant diplomas and certificates must be submitted.'
                    )
                  "
                >
                  <label
                    for="apply_for"
                    class="text-primary-dark font-weight-600"
                    >{{ $t("CV or Bio Data information") }}</label
                  >
                  <validation-provider
                    :name="$t('CV or Bio Data information')"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="form.doc_cv"
                      :placeholder="$t('No file chosen')"
                      :drop-placeholder="$t('Drop file here')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small>{{ tempSchool.doc_cv }}</small>
                </b-form-group>
                <b-form-group
                  :description="
                    $t(
                      '* Details and history of the yoga school with a short description of the style and tradition of yoga that your school provides yoga teacher training course(s).'
                    )
                  "
                >
                  <label
                    for="apply_for"
                    class="text-primary-dark font-weight-600"
                    >{{ $t("School Details")
                    }}<span class="text-danger">*</span></label
                  >
                  <validation-provider
                    :name="$t('School Details')"
                    :rules="!tempSchool.doc_school_detail ? 'required' : ''"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="form.doc_school_detail"
                      :placeholder="$t('No file chosen')"
                      :drop-placeholder="$t('Drop file here')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small>{{ tempSchool.doc_school_detail }}</small>
                </b-form-group>
                <b-form-group
                  :description="
                    $t(
                      '* A copy of training syllabus for the yoga teacher training course(s), indicating clearly educational areas to cover, total number of contact and non-contact hour standards for each educational area.'
                    )
                  "
                >
                  <label
                    for="apply_for"
                    class="text-primary-dark font-weight-600"
                    >{{ $t("Syllabus / Curriculum") }}</label
                  >
                  <validation-provider
                    :name="$t('Syllabus / Curriculum')"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="form.doc_syllabus_curriculum"
                      :placeholder="$t('No file chosen')"
                      :drop-placeholder="$t('Drop file here')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small>{{ tempSchool.doc_syllabus_curriculum }}</small>
                </b-form-group>
                <b-form-group
                  :description="
                    $t(
                      '* A copy of “Code of Ethics” for the yoga teacher training courses. (Registered yoga schools must include their Code of Ethics in their curriculum and set a good example for their students)'
                    )
                  "
                >
                  <label
                    for="apply_for"
                    class="text-primary-dark font-weight-600"
                    >{{ $t("Training / Course Outline")
                    }}<span class="text-danger">*</span></label
                  >
                  <validation-provider
                    :name="$t('Training / Course Outline')"
                    :rules="
                      !tempSchool.doc_training_course_outline ? 'required' : ''
                    "
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="form.doc_training_course_outline"
                      :placeholder="$t('No file chosen')"
                      :drop-placeholder="$t('Drop file here')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small>{{ tempSchool.doc_training_course_outline }}</small>
                </b-form-group>
                <b-form-group md="12" lg="12" class="mb-3 text-13">
                  <b-form-checkbox
                    id="accept"
                    v-model="acceptSelected"
                    name="accept"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    {{
                      $t(
                        "I accept the terms and use & Condition/Privacy of WYA Yoga"
                      )
                    }}
                    (<span
                      ><router-link
                        :to="{ name: 'termsAndConditions' }"
                        exact
                        class="font-weight-bold text-primary-dark ml-2 mr-2"
                        target="_blank"
                        >{{ $t("Terms and Conditions") }}</router-link
                      ></span
                    >
                    |
                    <span>
                      <router-link
                        :to="{ name: 'privacyPolicy' }"
                        exact
                        class="font-weight-bold text-primary-dark ml-2 mr-2"
                        target="_blank"
                        >{{ $t("Privacy Policy") }}</router-link
                      ></span
                    >)
                  </b-form-checkbox>
                </b-form-group>
                <b-row class="justify-content-center mt-4">
                  <b-button
                    type="reset"
                    @click="onPrevious"
                    pill
                    class="btn-default text-primary-dark font-weight-bold px-5"
                    >{{ $t("Previous") }}</b-button
                  >
                  <b-button
                    type="submit"
                    pill
                    :disabled="isLoading || acceptSelected == 'not_accepted'"
                    class="btn-primary-dark ml-3 font-weight-bold px-5"
                    ><b-spinner
                      small
                      class="mr-3"
                      v-if="isLoading"
                      label="Loading..."
                    />{{ $t("Next") }}</b-button
                  >
                </b-row>
              </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import StepFormRegister from "../../components/form/StepFormRegister";
import Api from "../../api/api";

export default {
  name: "SchoolRegisterDocument",
  title: " | School register document",
  components: {
    StepFormRegister,
  },
  data() {
    return {
      currentStep: 2,
      acceptSelected: "not_accepted",
      titleListSteps: [
        this.$t("Start"),
        this.$t("School info"),
        this.$t("Yoga info"),
        this.$t("Document"),
        this.$t("Payment"),
        this.$t("Finished"),
      ],
      form: {
        id: null,
        doc_logo: null,
        doc_cv: null,
        doc_school_detail: null,
        doc_syllabus_curriculum: null,
        doc_training_course_outline: null,
      },
      isLoading: false,
      tempSchool: {
        doc_logo: null,
        doc_cv: null,
        doc_school_detail: null,
        doc_syllabus_curriculum: null,
        doc_training_course_outline: null,
      },
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters.userProfile;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.toggleBurgerButtonClass("addClass", "bars-bg-gray");
    this.getSchool();
  },
  methods: {
    toggleBurgerButtonClass(addRemoveClass, className) {
      const burger = document.querySelector(".bm-burger-button");
      if (addRemoveClass === "addClass") {
        burger.classList.add(className);
      } else {
        burger.classList.remove(className);
      }
    },
    onSubmit() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.updateSchool();
        }
      });
    },
    onPrevious() {
      this.$router.push({ name: "schoolRegisterYogaAndFaculty" });
    },
    getSchool() {
      Api.listSchool({ user: this.userProfile.id }).then((response) => {
        if (response.data.results) {
          this.form.id = response.data.results[0].id;
          this.tempSchool = { ...this.tempSchool, ...response.data.results[0] };
        }
      });
    },
    updateSchool() {
      this.isLoading = true;
      Api.updateFileDocumentSchool(this.form)
        .then((response) => {
          this.isLoading = false;
          this.$router.push({ name: "schoolRegisterPayment" });
        })
        .catch((error) => {
          this.isLoading = false;
          const responseError = error.response;
          let textError = "";
          if (responseError) {
            textError = Object.values(responseError.data).join();
          } else {
            textError = this.$t("Unsuccessfully register yoga school");
          }
          this.$notify({
            group: "error",
            title: this.$t("Register yoga school"),
            text: textError,
          });
        });
    },
  },
  destroyed() {
    this.toggleBurgerButtonClass("removeClass", "bars-bg-gray");
  },
};
</script>
<style >
</style>
