var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"base-container-x pt-2",staticStyle:{"min-height":"400px","background-color":"#fff"}},[_c('b-row',{staticClass:"mb-5 justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('StepFormRegister',{attrs:{"currentStep":3,"titleListSteps":this.titleListSteps}})],1),_c('b-col',{staticClass:"border rounded py-4 py-md-5 border-light-gray",attrs:{"xl":"9","lg":"9","sm":"12","md":"12"}},[_c('h3',{staticClass:"content-header-title text-primary-dark"},[_vm._v(" "+_vm._s(_vm.$t("Document"))+" ")]),_c('div',{staticClass:"mt-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t("Logo"))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Logo'),"rules":!_vm.tempSchool.doc_logo ? 'required' : '',"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_logo),callback:function ($$v) {_vm.$set(_vm.form, "doc_logo", $$v)},expression:"form.doc_logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.tempSchool.doc_logo))])],1),_c('b-form-group',{attrs:{"description":_vm.$t(
                    '* Curriculum Vitae (CV) for the Director or the primary instructor, if the director is not involved in the yoga teacher training course and any other supporting yoga instructor.Photocopies of relevant diplomas and certificates must be submitted.'
                  )}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t("CV or Bio Data information")))]),_c('validation-provider',{attrs:{"name":_vm.$t('CV or Bio Data information'),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_cv),callback:function ($$v) {_vm.$set(_vm.form, "doc_cv", $$v)},expression:"form.doc_cv"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.tempSchool.doc_cv))])],1),_c('b-form-group',{attrs:{"description":_vm.$t(
                    '* Details and history of the yoga school with a short description of the style and tradition of yoga that your school provides yoga teacher training course(s).'
                  )}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t("School Details"))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('School Details'),"rules":!_vm.tempSchool.doc_school_detail ? 'required' : '',"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_school_detail),callback:function ($$v) {_vm.$set(_vm.form, "doc_school_detail", $$v)},expression:"form.doc_school_detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.tempSchool.doc_school_detail))])],1),_c('b-form-group',{attrs:{"description":_vm.$t(
                    '* A copy of training syllabus for the yoga teacher training course(s), indicating clearly educational areas to cover, total number of contact and non-contact hour standards for each educational area.'
                  )}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t("Syllabus / Curriculum")))]),_c('validation-provider',{attrs:{"name":_vm.$t('Syllabus / Curriculum'),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_syllabus_curriculum),callback:function ($$v) {_vm.$set(_vm.form, "doc_syllabus_curriculum", $$v)},expression:"form.doc_syllabus_curriculum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.tempSchool.doc_syllabus_curriculum))])],1),_c('b-form-group',{attrs:{"description":_vm.$t(
                    '* A copy of “Code of Ethics” for the yoga teacher training courses. (Registered yoga schools must include their Code of Ethics in their curriculum and set a good example for their students)'
                  )}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t("Training / Course Outline"))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Training / Course Outline'),"rules":!_vm.tempSchool.doc_training_course_outline ? 'required' : '',"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_training_course_outline),callback:function ($$v) {_vm.$set(_vm.form, "doc_training_course_outline", $$v)},expression:"form.doc_training_course_outline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.tempSchool.doc_training_course_outline))])],1),_c('b-form-group',{staticClass:"mb-3 text-13",attrs:{"md":"12","lg":"12"}},[_c('b-form-checkbox',{attrs:{"id":"accept","name":"accept","value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.acceptSelected),callback:function ($$v) {_vm.acceptSelected=$$v},expression:"acceptSelected"}},[_vm._v(" "+_vm._s(_vm.$t( "I accept the terms and use & Condition/Privacy of WYA Yoga" ))+" ("),_c('span',[_c('router-link',{staticClass:"font-weight-bold text-primary-dark ml-2 mr-2",attrs:{"to":{ name: 'termsAndConditions' },"exact":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("Terms and Conditions")))])],1),_vm._v(" | "),_c('span',[_c('router-link',{staticClass:"font-weight-bold text-primary-dark ml-2 mr-2",attrs:{"to":{ name: 'privacyPolicy' },"exact":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("Privacy Policy")))])],1),_vm._v(") ")])],1),_c('b-row',{staticClass:"justify-content-center mt-4"},[_c('b-button',{staticClass:"btn-default text-primary-dark font-weight-bold px-5",attrs:{"type":"reset","pill":""},on:{"click":_vm.onPrevious}},[_vm._v(_vm._s(_vm.$t("Previous")))]),_c('b-button',{staticClass:"btn-primary-dark ml-3 font-weight-bold px-5",attrs:{"type":"submit","pill":"","disabled":_vm.isLoading || _vm.acceptSelected == 'not_accepted'}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(_vm._s(_vm.$t("Next")))],1)],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }